$color-white: #FFFFFF;
$color-primary: #111827;
$color-secondary: #818cf8;
$color-dark-gray: #9ca3af;
$color-gray: #ffffffb3;
$color-light-black: #343434;
$color-body-background: #F1F5F9;

$primaryColor: #1E2735; //161931
$lighPrimaryColor: #161e2a; //1A1F44
$secondaryColor: #C2C2FA;
$sidebarTextColor: #a1aec5; //788DB4
$backgrounColor: #F6F6F6;
$whiteColor: #FFFFFF;
$grayColor: #7A7878;
$lightGrayColor: #E5E5E5;
$linkColor: #1A5BE1;
$lightTextColor: #343434;
$darkTextColor: #202124;
$lightOceanColor: #E5FDFF;
$darkOceanColor: #39D4E8;
$iconColor: #504973;
$lightBtnColor: #F8F7FC;
$tableHeaderColor: #F8F7FC; //E7E5F2 
$inputBorderColor: #D5D5D5;
$grayishBlueColor: #F8FAFB;
$yellowColor: #FAA60C;
$lightYellowColor: #FFCE67;
$darkBlueColor: #191D38;
$lightGreenColor: #01CC66;
$labelColor: #a39e9e;
$lightPurpleColor: #736cc7;
$purpleColor: #B47FFE;

$successLightColor: #EBFBEE;
$successDarkColor: #4FA85F;
$errorLightColor: #FFF5F4;
$errorDarkColor: #D93536;
$warningLightColor: #FFF9DC;
$warningDarkColor: #EF8C01;
$infoLightColor: #E7F5FF;
$infoDarkColor: #1F70BA;

$dangerColor: #FF3D3C;
$dangerLightColor: #FFEEED;
// ==================================================

$primaryRed: #C1080C;