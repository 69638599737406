// .login-container{
//     width: 100%;
//     height: 100vh;
//     background-image: url('../../images/login-background.jpg');
//     background-size: cover;
//     @include flexCenter();
//     .login-card{
//         background-color: $color-white;
//         padding: 1.5rem;
//         max-width: 400px;
//         width: 100%;
//         box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
//         border-radius: 10px;
//         .login-form-title{
//             font-size: 23px;
//             font-weight: 600;
//             color: $color-light-black;
//             line-height: 1.2;
//             letter-spacing: 0.5;
//             text-align: center;
//             text-transform: uppercase;
//             margin-bottom: 2rem;
//         }
//     };
//     .signup-card{
//         background-color: $color-white;
//         padding: 1.5rem;
//         max-width: 800px;
//         width: 100%;
//         box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
//         border-radius: 10px;
//         .login-form-title{
//             font-size: 23px;
//             font-weight: 600;
//             color: $color-light-black;
//             line-height: 1.2;
//             letter-spacing: 0.5;
//             text-align: center;
//             text-transform: uppercase;
//             margin-bottom: 2rem;
//         }
//     }

// }

// .body{
//     margin: 0%;
//     padding: 0%;
//     box-sizing: border-box;
//     background-color: blue;
// }

// .polygon1{
//     width: 832px;
//     height: 806px;
//     background: rgba(147, 188, 47, 0.75);
//     position: absolute;
//     left: 792px;
//     top: -94px;
//     transform: rotate(-40.63deg);
//     z-index: -1;
// }

// .polygon2{
//     width: 872px;
//     height: 932px;
//     background: rgba(157, 190, 26, 0.53);
//     position: absolute;
//     left: 818px;
//     top: -202px;
//     transform: rotate(-40.63deg);
//     z-index: -1;
// }
// .main-auth-section{
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     min-height: 100vh;
//     width: 100%;
//     overflow: hidden;
//     position: relative;
// }
// .container{
//     display: flex;
//     height: auto;
//     border: 2px solid black;
//     margin: auto;
 
//     border-radius: 45px 0px 45px 0px;
// }
// .form{
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     margin-left: 30px;
         
// }
// .form h1{
//     align-self: flex-start;
//     font-size: 3rem;
//     font-family: lato;
//     margin-top: 50px;
//     cursor: pointer;                        
// }
// .form h4{
//     font-size: 1rem;
//     font-family: lato;
//     align-self: flex-start;
//     cursor: pointer;
// }
// .box{
//     padding: 10px;
//     width: 103%;
//     border-top: none;
//     border-left: none;
//     border-right: none;
//     margin-top: 12px;
//     background: none;
//     font-family: lato;
// }
// #submit {
//     padding: 12px 30px;
//     margin-top: 26px;
//     background-color: green;
//     color: white;
//     width: 200px;
//     border-radius: 25px;
//     font-family: lato;
//     border: none;
// }
// #submit:hover{
//     cursor: pointer;
//     background-color: blue;
// }

// .icon{
//     display: flex;
//     width: 100%;
//     align-items: flex-end;
// }

// .input-icon{
//     width: 100%;
// }

// .form a{
//     text-decoration: none;
//     font-family: lato;
//     margin-top: 20px;
//     color: black;
// }
// .form a:hover{
//     cursor: pointer;
//     color: brown;
// }
// .or{
//     width: 36px;
//     height: 36px;
//     text-align: center;
//     color: black;
//     font-size: 20px;
//     font-family: lato;
//     border: 2px solid green;
//     border-radius: 25px;
//     background-color:  #FFF8F8;
//     line-height: 32px;
//     margin-top: 20px;
//     cursor: pointer;

// }

// .fa-facebook-f{
//     border: 2px solid green;
//     width: 30px;
//     height: 30px;
//     align-items: stretch;
//     justify-content: right;
//     border-radius: 25px;
//     background-color: blue;
//     color: white;
//     margin: 16px 78px 0px 0px;
//     text-align: center;
//     line-height: 26px !important;
//     cursor: pointer;
// }

// .fa-google-plus-g{
//     border: 2px solid green;
//     width: 30px;
//     height: 30px;
//     align-items: stretch;
//     justify-content: right;
//     border-radius: 25px;
//     background-color: orangered;
//     color: white;
//     margin: -30px 0px 0px 80px;
//     text-align: center;
//     line-height: 26px !important;
//     cursor: pointer;
// }
// .register{
//     height: 24px;
//     color: black;
//     font-family: lato;
//     margin-top: 20px;
// }












// .right-side{
    
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     height: 100%;
    
    
// }
// .right-side img{
//     width: 100%;
//     height: 260px;
//     object-fit: contain;
//     cursor: pointer;
// }
// .welcome{
//     text-align: center;
//     font-size: 2rem;
//     color: black;
//     font-family: lato;
//     cursor: pointer;
// }
// .back{
//     text-align: center;
//     font-size: 2rem;
//     color: black;
//     font-family: lato;
//     cursor: pointer;
// }
// @media screen and (max-width: 919px) {
//     .fa-facebook-f{
//         background-color: black;
//     }

// }

body {
    margin: 0;
}

.main-auth-section {
    // display: flex;
    /* align-content: center; */
    // justify-content: center;
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
    // position: relative;
}

.form {
    /* width: 85%;
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    min-height: 300px;
    margin-top: 24px; */
padding: 0px 15px 0px 30px;
width: 80%;
}

.main-auth-section h1 {
    font-weight: 800;
    color: #183059;
    margin: 25px 0px 0px 25px;
}

.main-auth-section p {
    font-family: 'Lato';
    font-size: 18px;
    line-height: 22px;
    color: rgba(24, 48, 89, 0.6);
    margin: 10px 0px 0px 30px;
}

.email-password {
    width: 100%;
    margin-top: 60px;
}

.input-icon {
    width: 100%;
}

.icon {
    display: flex;
    width: 100%;
    align-items: flex-end;
}

.box {
    padding: 10px;
    width: 100%;
    border-top: none;
    border-left: none;
    border-right: none;
    margin-top: 12px;
    background: none;
    font-family: lato;
    border-bottom: 1px solid rgba(73, 72, 69, 0.37);
}

.icon label {
    color: rgba(73, 72, 69, 0.37);
    margin-bottom: -2px;
}

.forgot-password {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 29px;
    /* identical to box height */


    color: #050505;
}

.form a {
    color: black;
    text-decoration: none;
    font-family: lato;

}


















.image-box {
    display: flex;
    width: 100%;
    margin-top: 30px;
}

.image-box img {
    width: 100%;
    height: 550px;
    object-fit: contain;
}

#submit {
    padding: 12px 30px;
    margin-top: 10px;
    background: rgba(54, 172, 44, 0.75);
    color: white;
    width: 100%;
    border-radius: 8px;
    font-weight: 600;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

#submit:hover{
    background: rgba(53, 134, 46, 0.75);
}

.logo-box {
    display: flex;
    justify-content: flex-start;
    margin-left: 30px;

}


.creat-account a {

    display: flex;
    justify-content: center;
    margin-top: 16px;

    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-decoration: none;

    color: #050505;
}

span {
    color:
        rgba(84, 194, 66, 1);
}

.logo {

    display: flex;
    justify-content: center;
}

.logo-box img {
    height: 65px;
    width: 65px;
    margin-top: 35px;
}

.facebook-icon {
    margin-right: 22px;
}

.twitter-icon {
    margin-right: 30px;
}

.github-icon {
    margin-right: 8px;
}

.google-icon {
    margin-left: 14px;
}



.or hr {
    width: 125px;
    border: none;
    border-bottom: 1px solid rgb(58 53 65 / 60%);
}

/* .or::after {
    content: "";
    width: 180px;
    height: 0px;
    display: block;

    position: absolute;
    top: 15px;
    right: 30px;
    border: 1px solid rgba(58, 53, 65, 0.17);
} */

.or label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height, or 120% */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.15px;
    margin: 0 10px 0 10px;

}

.bottom-shade {
    position: relative;
}

.bottom-shade svg{
    position: absolute;
    /* height: 416px; */
    top: -184px;
    left: 0px;
    z-index: -1;
    width: 100.8%;
}

@media (max-width: 575px) {
    .form{
        width : 100%;
    }
  }