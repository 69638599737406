.error-page-container{
    padding: 20px;
    width: 100vw;
    height: 100vh;
}

.error-page-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.error-page-body{
    height: calc(100vh - 40px);
    overflow: auto;
}

.error-text{
    h2{
        color: $yellowColor;
        font-weight: 400;
        text-transform: uppercase;
        font-size: 35px;
    }
    p{
        font-weight: 400;
        font-size: 20px;
    }
    b{
        font-size: 20px;
    }
}