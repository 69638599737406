section {
  background: #FFFFFF;
  box-shadow: 1px 2px 15px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  margin: 10px;
  // position: relative;
  // z-index: -1;
  max-height: calc(100vh - 115px);
  overflow: auto;
  padding: 5px 20px;

  hr{
    margin: 0;
  }
}

.main-heading {
  box-sizing: border-box;
  /* border-bottom: 0.5px solid rgba(35, 57, 91, 0.4); */
  display: flex;
  justify-content: space-between;
  // height: 65px;
 
}

.action-buttons {
  display: flex;
  justify-content: space-between;
  padding: 12px 0px;
}

.add-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 15px;
  gap: 10px;

  background: #263238;
  border-radius: 5px;
  border: none;
  margin-left: 25px;
}

.add-button span {
  font-family: 'Gordita';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #FFFFFF;


  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.filter-button {
  border: none;
  background: none;
  white-space: nowrap;
}

.filter-button span {
  font-family: 'Gordita';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #202020;
}

.main-heading h1 {
  font-family: 'Gordita';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 43px;


  display: flex;
  align-items: center;
  text-align: center;

  color: #323E43;
  align-items: center;
  margin: 10px;
}

.table {
  max-width: 100%;
  height: calc(100vh - 210px);
  overflow: auto;

  .MuiTablePagination-displayedRows,.MuiTablePagination-selectLabel{
    margin-top: auto;
  }
}

tbody tr:hover,.MuiDataGrid-row:hover {
  background: #FFFFFF;
  // box-shadow: 0px 0px 45px 79px rgba(179, 179, 179, 0.1);
  box-shadow: 0px 20px 45px rgba(52, 52, 52, 0.15);
  border: none;
  min-height: 50px !important;
  cursor: pointer;
}

// .table table {
//   width: 100%;
// }

.table .MuiDataGrid-columnHeader{
  min-width: 162px !important;
  max-width: 162px !important;
}

.table .MuiDataGrid-columnHeaderTitle,th {
  font-family: 'Gordita';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  /* or 100% */
 

  color: #666666;
}

.table tr,.MuiDataGrid-row {
  border: none;
  min-height: 50px !important;

}

.table .MuiDataGrid-cell{
  min-height: 50px !important;
  max-width: 162px !important;
  min-width: 162px !important;
  text-align: left !important;
  justify-content: flex-start !important;
}

.table td,
input {
  margin-left: 35px;
}


.main-dashboard-section {
  // width: calc(100% - 265px);
  height: 100%;

  .main-container {
    height: calc(100vh - 62px);
    overflow: auto;
    padding: 1rem;
    border-radius: 4px;

    .action-buttons {
      .primary-btn {
        margin-left: 5px;
      }
    }

    .filter-action-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .filter-input {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .form-item {
        width: 250px;
      }
    }

    .filter-status {

      .button-group {
        // border-radius: 25px;
        border: none;
        box-shadow: none;
      }

      .active,
      .inactive:hover {
        background-color: $color-primary;
        border: 1px solid $color-primary;
        border-radius: 25px;
        color: $color-white;
        cursor: pointer;
        margin: 4px;
        padding-top: 2px;
        padding-bottom: 2px;
      }

      .inactive {
        background: none;
        border: none;
        cursor: pointer;
        color: $color-primary;
        border-radius: 25px;
        margin: 4px;
        padding-top: 2px;
        padding-bottom: 2px;
      }
    }

    .filter-leave {
      .button-group {
        border: 1px solid #000000;
        border-radius: 4px;
        box-shadow: none;
      }

      .active,
      .inactive:hover {
        background-color: $color-primary;
        border: 1px solid $color-primary;
        border-radius: 4px;
        color: $color-white;
        cursor: pointer;
        margin: 4px;
        padding-top: 2px;
        padding-bottom: 2px;
      }

      .inactive {
        background: none;
        border: none;
        cursor: pointer;
        color: $color-primary;
        border-radius: 4px;
        margin: 4px;
        padding-top: 2px;
        padding-bottom: 2px;
      }
    }
  }

  .main-container-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .main-header {
    margin-bottom: 1rem;
  }

  .title-action-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }


  .data-table {
    height: calc(100% - 120px);
    overflow: auto;

    .datatable-header {
      // background-color: red;
    }
  }

  // .main-heading {
  //   padding-left: 25px;
  // }

  .main-container-body {
    height: calc(100% - 50px);
    overflow: auto;
    padding: 25px;

    .form-item {
      margin: 30px 30px 0px 0px;
    }

    .edit-image {
      position: relative;
      margin: 30px 0px 0px 0px;
    }

    .edit-image img {
      border-radius: 50%;
      border: 1px solid #111827;
      width: 300px;
      /* height: 28%; */
      height: 300px;
      object-fit: cover;
    }

    .edit-image-label {
      position: absolute;
      top: 250px;
      left: 220px;
      background: #111827;
      width: 40px;
      height: 40px;
      text-align: center;
      border-radius: 50%;
      color: #ffffff;
      font-size: 22px;
    }

    .action-footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;
      margin-top: 35px;
      width: 100%;
    }
  }

}

.main-drawer-area {
  height: 100vh;

  .drawer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }

  .drawer-body {
    height: calc(100% - 109px);
    overflow: hidden;
    padding: 10px;
  }

  .drawer-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
    gap: 10px;
  }
}

.main-dialog-area {
  width: 100%;
  height: 100vh;

  .dialog-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 15px 0px 20px;

    .dialog-title {
      font-size: 24px;
      font-weight: 600;
    }

    .dialog-close {
      border-radius: 5px;
      background-color: rgba(0, 0, 0, 0.04);
      margin-left: 20px;
    }

    .dialog-close:hover {
      background-color: $color-primary;
      color: #FFFFFF;
    }
  }

  .dialog-content {
    padding: 10px 15px 24px 15px;

    .dialog-item {
      padding: 10px 10px 0px 10px;

      .info-label {
        font-family: "Lato";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 25px;
        display: flex;
        align-items: center;
        color: #7A7878
      }

      .info-value {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 25px;
        display: flex;
        align-items: center;

        color: #202124;

      }
    }
  }
}

.grey-row {
  background-color: #D9D9D9;
}