// loader animation

.loader{
    background-color: #000000af;
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: 10000;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cubes {
    width: 40px;
    height: 40px;

}
   
.cubes .sk-cube {
width: 33%;
height: 33%;
background-color: #E5E5E5;
float: left;
animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}
   
.cubes .sk-cube1 {
animation-delay: 0.2s;
}

.cubes .sk-cube2 {
animation-delay: 0.3s;
}

.cubes .sk-cube3 {
animation-delay: 0.4s;
}

.cubes .sk-cube4 {
animation-delay: 0.1s;
}

.cubes .sk-cube5 {
animation-delay: 0.2s;
}

.cubes .sk-cube6 {
animation-delay: 0.3s;
}

.cubes .sk-cube7 {
animation-delay: 0s;
}

.cubes .sk-cube8 {
animation-delay: 0.1s;
}

.cubes .sk-cube9 {
animation-delay: 0.2s;
}

@keyframes sk-cubeGridScaleDelay {
0%,
70%,
100% {
    transform: scale3D(1, 1, 1);
}
35% {
    transform: scale3D(0, 0, 1);
}
}

// Fallback component animation

.triple-spinner {
    display: block;
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 3px solid transparent;
    margin-bottom: 30px;
    border-top: 3px solid $iconColor;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

.triple-spinner::before,
.triple-spinner::after {
content: "";
    position: absolute;
    border-radius: 50%;
    border: 4px solid transparent;
}
.triple-spinner::before {
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-top-color: $linkColor;
    -webkit-animation: spin 3s linear infinite;
    animation: spin 3.5s linear infinite;
}
.triple-spinner::after {
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-top-color: $darkOceanColor;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.75s linear infinite;
}


@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
  