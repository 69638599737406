@media screen and (max-width: 575px) {
    .sidebar-right .sidebar {
        max-width: 320px !important;
    }

    .login-area {
        padding: 20px;

        .login-form {
            width: 100%;
        }
    }
    // .card-container-top{
    //     align-items: flex-start !important;
    // }
    // .card-header-action{
    //     flex-wrap: wrap-reverse;
    //     justify-content: flex-end;
    // }

    .add-btn {
        width: 37px;
        height: 37px;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            display: none;
        }
    }
    .profile-name{
        display: none;
    }
    .dashboard-container{
        padding:0;
    }
    .dashboard-detail-area{
        padding:0;

    }
}