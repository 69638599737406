.toast-container {
  position: fixed;
  bottom: 10px;
  left: 15px;
  z-index: 25000;

  .custom-toast {
    width: 350px;
    margin-bottom: 10px;
    border-radius: 10px;
    box-shadow: 0 0 14px 3px rgba(100, 100, 111, 0.1);
    animation: myfirst 0.5s;
    position: absolute;
    left: 0;
    bottom: 10px;

    @keyframes myfirst {
      0% {
        left: -30px;
        bottom: 10px;
      }

      100% {
        left: 0;
        bottom: 10px;
      }
    }

    label {
      font-weight: 500;
      font-size: 15px;
    }

    span {
      font-weight: 400;
      font-size: 15px;
      margin-top: 6px;
    }

    .success-toast-progress {
      height: 8px;
      border-radius: 6px 6px 0 0;

      .p-progressbar-value {
        height: 8px;
        background: $successDarkColor;
        border-radius: 6px 6px 0 0;
      }

      .p-progressbar-label {
        display: none;
      }
    }

    .error-toast-progress {
      height: 8px;
      border-radius: 6px 6px 0 0;

      .p-progressbar-value {
        height: 8px;
        background: $errorDarkColor;
        border-radius: 6px 6px 0 0;
      }

      .p-progressbar-label {
        display: none;
      }
    }

    .warning-toast-progress {
      height: 8px;
      border-radius: 6px 6px 0 0;

      .p-progressbar-value {
        height: 8px;
        background: $warningDarkColor;
        border-radius: 6px 6px 0 0;
      }

      .p-progressbar-label {
        display: none;
      }
    }
    .p-mr-auto {
      margin-right: auto !important
  }

    .info-toast-progress {
      height: 8px;
      border-radius: 6px 6px 0 0;

      .p-progressbar-value {
        height: 8px;
        background: $infoDarkColor;
        border-radius: 6px 6px 0 0;
      }

      .p-progressbar-label {
        display: none;
      }
    }

    &.success-msg {
      background-color: $successLightColor;
      color: $successDarkColor;
    }

    &.error-msg {
      background-color: $errorLightColor;
      color: $errorDarkColor;
    }

    &.warning-msg {
      background-color: $warningLightColor;
      color: $warningDarkColor;
    }

    &.info-msg {
      background-color: $infoLightColor;
      color: $infoDarkColor;
    }
  }
}