.sidebar-container{
    background-color: $color-primary;
    height: 100%;
    float: left;
    flex-shrink: 0;
    height: 100vh;
    overflow: visible;
    position: relative;
    transition: all 1s;
    width: 260px;
}

.close-sidebar{
    overflow: hidden;
    -webkit-transform: overflow 1s;
    transform: overflow 1s;
    transition: 1s;
    width: 80px;
}

.sidebar-header{
    padding: 15px 20px;
    display: flex;
    align-items: center;
    .logo-container{
        width: 150px;
        margin: auto;
        img{
            width: 100%;
        }
    }
}

.close-button{
    z-index: 999;
}

.sidebar-body{
    padding: 1rem;
    .sidebar-content-header{
        .header-text{
            font-size: 12px;
            color: $color-secondary;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 0.05em;
            line-height: 20px;
        }
        .subheader-text{
            font-size: 11px;
            color: $color-dark-gray;
            letter-spacing: 0.06px;
            font-weight: 500;
            line-height: 1.5;
        }
    }
    .tabs-container{
        padding-left: 5px;
        list-style: none;
        .tabs-list{
            margin-bottom: 0.5rem;
            a{
                -webkit-tap-highlight-color: transparent;
                background-color: transparent;
                outline: 0px;
                border: 0px;
                user-select: none;
                vertical-align: middle;
                appearance: none;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                position: relative;
                box-sizing: border-box;
                text-align: left;
                transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                min-height: 44px;
                width: 100%;
                border-radius: 6px;
                margin: 0px 0px 4px;
                padding: 10px 16px;
                color: $color-gray;
                text-decoration: none;
                &:hover{
                    background-color: #ffffff1a;
                }
            }
        }
    }
}