.primary-main-btn{
    padding: 4px 15px;
    border-radius: 5px;
    cursor: pointer;
    display: inline-flex;
    cursor: pointer;
    background-color: $color-primary;
    border: 1px solid $color-primary;

    color: $color-white;
}
.primary-btn {
    padding: 4px 15px;
    border-radius: 25px;
    cursor: pointer;
    display: inline-flex;
    cursor: pointer;
    background-color: $color-primary;
    border: 1px solid $color-primary;

    color: $color-white;
}
.secondary-btn {
    padding: 4px 15px;
    border: 1px solid $color-primary;
    border-radius: 25px;
    cursor: pointer;
    display: inline-flex;
    cursor: pointer;
    color: $color-primary;
    background: none;
}
.close-btn {
  width: 35px;
  height: 35px;
    border-radius: 50%;
    display: flex;
    cursor: pointer;
    background-color: $color-primary;
    align-items: center;
    justify-content: center;
    color: $color-white;
}