.main-section{
    display: flex;
}


::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-thumb {
    background-color: $color-primary;
    border-radius: 3px;
}
// .d-flex{
//     display: flex;
//     flex-wrap: wrap;
// }

// .form-item{
//     width: 50%;
//     padding: 0 8px;
//     margin-bottom: 12px;
// }
.form-item>div{
    width: 100%;
}

// .MuiInputBase-input,.MuiSelect-select{
//     padding-top: 8px !important;
//     padding-bottom: 8px !important;
// }