@media screen and (max-width: 767px) {
    .sidebar-right .sidebar {

        .checkbox-label {
            width: 55px;
        }
    }
    
    .datatable-scrollable .datatable-wrapper {
        height: auto;
      min-height: calc(100vh - 264px);
    }
    .filter-panel{
        width: 80% !important;
    }
}