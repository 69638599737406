@media screen and (max-width: 991px) {
  .datatable-scrollable .datatable-wrapper {
    height: auto;
    min-height: calc(100vh - 211px);
  }

  .main-login-section {
    flex-direction: column;
    overflow-x: auto;
    // padding: 55px 0;

    .login-area {
      height: auto;
      margin: 30px auto;
    }

    .login-header {
      margin: 30px 0;
    }
  }

  .main-section {
    .sidebar-container {
      width: 0px;
      overflow: hidden;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      z-index: 9;

      .sidebar-header {
        &::after {
          width: 0px;
          transition: 1s;
        }
      }

      .sidebar-logo-area,
      .sidebar-menu {
        position: relative;
        z-index: 3;
      }

      &.close-sidebar {
        width: 260px;

        .sidebar-head {
          &::after {
            width: 100%;
          }
        }

        .sidebarlogo {
          width: 200px;
          margin: 20px 30px;
          height: 100px;
          height: 100px;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        &::after {
          content: "";
          position: fixed;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          z-index: 1;
          // background-color: rgba($color: #000000, $alpha: 0.6);
        }
      }
    }

    .main-dashboard-section {
      width: 100%;

      &.lg-dashboard {
        width: 100%;
      }
    }
  }

  .main-drawer-container>div {
    width: 100%;
  }

  .main-drawer-container {
    .drawer-body {
      overflow-y: auto;
    }
  }

  nav .profile-img {
    margin: 5px 0px;
  }
  
  nav .logo-box-dashboard {
    width: 150px;
    margin-top: 8px;
    margin-left: 6px;
}
}