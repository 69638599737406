body{
  background: #F6F6F6;
}

nav {
  // filter: drop-shadow(0px 20px 45px rgba(52, 52, 52, 0.15));
// box-shadow: 0px 20px 45px rgba(52, 52, 52, 0.15);
  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  padding: 10px;
  background: #263238;
  opacity: 0.94;
  border-radius: 20px;
  max-height: 86px;
  margin: 10px;
  position: relative;
    z-index: 1;

  .logo-box-dashboard {
    width: 180px;
    margin-top: 6px;
    margin-left: 25px;

    img {
      width: 100%;
    }

    // margin: 15px 0px 15px 38px;
  }

  .menu-list {
    list-style-type: none;
    display: flex;
    margin-top: 15px;
  }

  .menu-list li:not(:first-child) {
    margin-left: 30px;
  }

  .menu-item.active {
    border: none;
    border-bottom: 4px solid #54C242;
    padding-bottom: 6px;
  }

  .menu-item.inactive a:hover {
    border: none;
    border-bottom: 4px solid #54C242;
    padding-bottom: 6px;
  }

  .menu-item a {
    font-family: 'Gordita';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    /* identical to box height */
    text-decoration: none;

    color: #FFFFFF;
  }

  .search-notification {
    display: flex;
    justify-content: end;
    margin-top: 12px;
    border-right: 1px solid #C5C5C5;
    padding-right: 23px;
    align-items: center;
  }

  .search-box {
    // width: 100%;
    // display: flex;
    position: relative;
  }


  .search-box input {
    width: 150px;
    background: #FFFFFF;
    border: 1px solid #C5C5C5;
    border-radius: 6px;
    margin-left: 20px;
    padding: 5px 20px;
    // height: 35px;
  }

  .search-box input:focus {
    outline: none;
  }

  .search-box input::placeholder {
    font-family: 'Gordita';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    // display: flex;
    /* align-items: center; */
    /* text-align: center; */

    // padding-left: 20px;
    color: #343434;
    opacity: 0.55;
  }


  .search-icon {
    position: absolute;
    right: 10px;
    top: 6px;
  }

  .search-icon i {
    color: #343434;
    opacity: 0.55;
  }

  .notification-icon {
    margin-left: 20px;
  }

  .user-profile {
    display: flex;
    cursor: pointer;
    position: relative;
  }

  .profile-img {
    margin: 10px 20px 0px 0px;
    width: 42px;
    height: 42px;
  }

  .profile-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  .toggle-overlay {
    position: absolute;
    top: 175%;
    right: 0px;
    bottom: 0;
    /* background-color: rgba(0, 0, 0, 0.85); */
    z-index: 999;
    color: white;
  }

  .main-overlay {
    background: #FFFFFF;
    // box-shadow: 0px 4px 20px rgba(100, 100, 111, 0.2);
    box-shadow: 0px 20px 45px rgba(52, 52, 52, 0.15);
    border-radius: 5px;
    width: 250px;
    height: 275px;
  }

  .overlay-item {
    border-top: 1px solid #E7E5F2;
  }

  .overlay-item ul {
    padding: 0;
    list-style-type: none;
  }

  .overlay-item li {
    color: #000000;
    
  }

  .overlay-item li:hover {
    background: #F9F9F9;
    border-radius: 4px;
  }

  .list-item {
    display: flex;
    padding: 10px 25px;
  }

  .list-item-title a {
    font-family: 'Gordita';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;

    color: #202124;
    text-decoration: none;
    margin-left: 15px;
  }

  .profile-detail {
    display: flex;
    margin-bottom: 15px;
    margin-left: 13px;
    align-items: center;
  }

  .last-login-info {
    border-top: 1px solid #E7E5F2;
  }

  .last-login-info p {
    font-family: 'Gordita';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;

    color: #202124;
    margin-top: 15px;
    margin-left: 12px;
  }

  .profile-info {
    margin: 9px 0px 0px 0px;
  }

  .username-overlay p {
    font-family: 'Gordita';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;

    color: #202124;
    /* identical to box height */
    text-align: center;

  }

  .username p {
    font-family: 'Gordita';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    // line-height: 17px;
    /* identical to box height */
    text-align: center;

    color: #FFFFFF;

  }


  .usertype {
    margin-top: -12px;
  }

  .usertype-overlay-badge p {
    font-family: 'Gordita';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    // line-height: 14px;
    margin: 0 auto;
    color: #000000;
  }

  .usertype-badge,
  .usertype-overlay-badge {
    display: flex;
    padding: 0px 10px;
    background: #FFD952;
    border-radius: 3px;
    width: 100%;
    height: 20px;
  }

  .usertype-badge p {
    font-family: 'Gordita';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    // line-height: 14px;

    color: #000000;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .mobile-menu{
    padding: 8px 12px;
    background: #FFFFFF;
    height: 42px;
    width: 42px;
    border-radius: 50%;
  }

}