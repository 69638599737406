a, button{
    cursor: pointer !important;
}

.flex-sidebyside{
    display: flex;
    align-items: flex-start;
}

.flex-sidebyside-between{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.flex-sidebyside-full{
    display: flex;
    align-items: flex-start;
    width: 100%;
}
.cursor-pointer{
    cursor: pointer;
}

// =====================================================================
.primary-text {
    color: $color-primary !important;
}

.secondary-text {
    color: $color-secondary !important;
}

.white-text {
    color: $color-white !important;

}

.light-black-text {
    color: $color-light-black !important;
}
// =====================================================================

.primary-bg {
    background-color: $color-primary !important;
}

.secondary-bg {
    background-color: $color-secondary !important;
}
.white-bg {
    color: $color-white !important;
}
// =====================================================================

.text-12{
    font-size: 12 !important;
}

.text-14{
    font-size: 14 !important;
}

.text-18{
    font-size: 18 !important;
}

.text-20{
    font-size: 20 !important;
}

.text-22{
    font-size: 22 !important;
}

.text-24{
    font-size: 24 !important;
}

// =====================================================================


.text-500{
    font-weight: 500 !important;
}

.text-600{
    font-weight: 600 !important;
}

.text-700{
    font-weight: 700 !important;
}