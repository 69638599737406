body {
    margin: 0%;
    padding: 0%;
    box-sizing: border-box;
    overflow: hidden;
    

}

.main-register-section {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    width: 100%;
    position: relative;
    
}

.polygon1-register {
    position: absolute;
    width: 870px;
    height: 931.39px;
    left: 810px;
    top: -230.42px;
    background: rgba(152, 189, 36, 0.53);
    transform: rotate(-45.63deg);
    z-index: -1;
}

.polygon2-register {
    position: absolute;
    width: 870.77px;
    height: 931.39px;
    left: 856.42px;
    top: -124px;
    background: rgba(152, 189, 36, 0.74);
    transform: rotate(-45.63deg);
    z-index: -1;
}



.flex-container {
    display: flex;
    width: 80%;
    margin: auto;
    border: 1px solid #98BD24;
    background: rgba(255, 251, 251, 0.85);
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 32px;
}

.form-register {
    font-family: lato;
    cursor: pointer;
    margin-top: 40px;
}

.box-register {
    margin: 36px 0px 0px 28px;
    border: none;
    font-family: lato;
    width: 90%;
    overflow: hidden;
}

.box-register input {
    width: 100%;
}

h1 {
    align-self: flex-start;
    font-family: lato;
    cursor: pointer;
    margin-left: 30px;
}

a.button {
    background-color: #7bb672;
    color: black;
    height: 40px;
    width: 200px;
    border-radius: 25px;
    font-size: 20px;
    padding: 10px;
    font-family: lato;
    text-decoration: none;
    display: flex;
    align-content: center;
    justify-content: center;
    margin-top: 10px;
}

.register {
    height: 24px;
    color: black;
    font-family: lato;
    display: flex;
    align-content: center;
    justify-content: center;
}

a#submit-register {
    text-decoration: none;
    margin-top: 26px;
    background: rgba(152, 189, 36, 0.6);
    color: black;
    width: 190px;
    height: 40px;
    border-radius: 25px;
    font-family: lato;
    display: flex;
    align-content: center;
    justify-content: center;
    line-height: 42px;
}

.button-register-box {
    display: flex;
    align-content: center;
    justify-content: center;
    margin-top: 26px;
}

.gender {}

.gender .gender-content {
    display: flex;
}